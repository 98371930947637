"use client";

import {
  AppBar,
  type AppBarProps,
  Box,
  Container,
  Drawer,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Logo } from "modules/shared/components/logo";
import {
  forwardRef,
  type ForwardRefExoticComponent,
  type PropsWithoutRef,
  type ReactNode,
  type RefAttributes,
  useState,
} from "react";
import { AccountButton } from "widgets/account-button";
import { AppMenu } from "../app-menu";
import { MobileMenu } from "../mobile-menu/MobileMenu";
import { MobilePersonalMenu } from "./mobile-personal-menu/MobilePersonalMenu";

const appMenuLinks = [
  {
    href: "/tools",
    label: "Tools",
    isExact: false,
    children: [
      {
        label: "Explorer",
        href: "https://promscan.io",
        target: "_blank",
      },
      {
        label: "Forge",
        href: "https://forge.prom.io",
        target: "_blank",
      },
      {
        label: "Bridge",
        href: "https://prom.io/bridge",
        target: "_blank",
      },
    ],
  },
  {
    href: "https://prom.io/learn",
    label: "Learn",
    isExact: false,
    children: [
      {
        href: "/blog",
        label: "Blog",
        target: "_self",
      },
      {
        href: "https://prom.gitbook.io/prom/quickstart/prom-token",
        label: "Prom Token",
        target: "_blank",
      },
      {
        label: "Validators",
        href: "https://prom.gitbook.io/prom/start-building/zknode",
        target: "_blank",
      },
      {
        label: "Ecosystem",
        href: "https://prom.io/partnership",
        target: "_blank",
      },
    ],
  },
  {
    href: "https://prom.io/developers",
    label: "Developers",
    isExact: false,
    children: [
      {
        label: "RPC Providers",
        href: "https://prom.gitbook.io/prom/quickstart/connecting-to-prom",
        target: "_blank",
      },
      {
        label: "Bug Bounty",
        href: "https://prom.gitbook.io/prom/start-building/bug-bounty-program",
        target: "_blank",
      },
      {
        label: "Documentation",
        href: "https://prom.gitbook.io/prom/quickstart",
        target: "_blank",
      },
      {
        label: "Grant",
        href: "https://prom.io/grant",
        target: "_blank",
      },
    ],
  },
  {
    href: "/community",
    label: "Community",
    isExact: false,
    children: [
      {
        label: "Governance",
        href: "https://prom.gitbook.io/prom/quickstart/governance",
        target: "_blank",
      },
      {
        href: "https://jobs.polymer.co/prom",
        label: "Career",
        target: "_blank",
      },
      {
        label: "Contacts",
        href: "https://prom.io/contacts",
        target: "_blank",
      },
    ],
    withSocials: true,
  },
];
export type HeaderProps = AppBarProps & {
  colorState?: "light" | "dark" | "transparent";
  landingMode?: boolean;
  children?: ReactNode;
};

export const Header: ForwardRefExoticComponent<
  PropsWithoutRef<HeaderProps> & RefAttributes<unknown>
> = forwardRef(
  ({ landingMode, children, colorState = "light", ...rest }, ref) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const isMobile = !isDesktop;

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <AppBar
        position={landingMode && !isMobile ? "sticky" : "fixed"}
        color="secondary"
        {...rest}
        ref={ref}
        sx={{
          bgcolor: theme.palette.background.default,
        }}
      >
        <Container
          maxWidth={landingMode ? "xl" : "lg"}
          sx={{ paddingInline: { lg: 10 } }}
          disableGutters
        >
          <Toolbar disableGutters>
            {!isMobile && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={28}
                sx={{
                  height: "100%",
                }}
              >
                <Logo style={{ width: "122px", height: "100%" }} href="/" />

                <AppMenu
                  links={appMenuLinks}
                  colorState={colorState}
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                  }}
                />
              </Stack>
            )}

            {children}

            <Box
              sx={{
                marginLeft: !isMobile ? "auto" : "none",
                width: isMobile ? "100%" : "inherit",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {isDesktop && (
                <>
                  <Stack direction="row" gap={4} alignItems="center">
                    <AccountButton />
                  </Stack>
                  <Drawer
                    open={isDrawerOpen}
                    anchor="right"
                    PaperProps={{ sx: { width: 500 } }}
                    onClose={() => setIsDrawerOpen(false)}
                  >
                    <MobilePersonalMenu
                      onlyContent
                      onClose={() => setIsDrawerOpen(false)}
                    />
                  </Drawer>
                </>
              )}

              {!isDesktop && (
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Logo style={{ width: "122px", height: "100%" }} href="/" />
                  <Stack flexDirection="row" gap={3}>
                    <MobilePersonalMenu />
                    <MobileMenu links={appMenuLinks} />
                  </Stack>
                </Stack>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
);

Header.displayName = "Header";
