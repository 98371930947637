"use client";

import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Divider,
  Menu,
  MenuItem,
  MenuProps,
  Stack,
  StackProps,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { SocialNetworks } from "modules/shared/components/social-networks/SocialNetworks";
import { nanoid } from "nanoid";
import NextLink from "next/link";
import { usePathname } from "next/navigation";
import { FC, MouseEventHandler, useState } from "react";
import { MenuLink } from "../mobile-menu/MobileMenu";
import { AppMenuLink } from "./styles";

type StyledMenuProps = {
  colorState: "transparent" | "light" | "dark";
} & MenuProps;

const StyledMenu = styled((props: StyledMenuProps) => <Menu {...props} />)(
  ({ theme, colorState }) => ({
    "& .MuiPaper-root": {
      backgroundColor:
        colorState === "light"
          ? "rgba(249, 249, 249, 0.85);"
          : "rgba(33, 33, 34, 0.85);",
      color:
        colorState === "light"
          ? theme.palette.text.primary
          : theme.palette.background.default,
    },
  })
);

export type AppMenuLink =
  | (MenuLink & { comingSoon?: false; withSocials?: boolean })
  | {
      comingSoon: true;
      label: string;
      withSocials?: boolean;
    };

type AppMenuProps = {
  links: AppMenuLink[];
  dashboardStyle?: boolean;
  colorState?: "transparent" | "light" | "dark";
} & StackProps;

const AppMenuDropdownLink: FC<{
  link: AppMenuLink;
  colorState?: "transparent" | "light" | "dark";
}> = ({ link, colorState = "light" }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

  const handleClick: MouseEventHandler<HTMLSpanElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (link.comingSoon) {
    return null;
  }

  const open = Boolean(anchorEl);
  const id = nanoid();

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <AppMenuLink
        sx={{ display: "flex", cursor: "pointer" }}
        $isActive={false}
        onClick={handleClick}
        as={"button"}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        {link.label}
        <KeyboardArrowDown sx={{ fontSize: "1em", marginInlineStart: 1 }} />
      </AppMenuLink>
      <StyledMenu
        id={id}
        colorState={colorState}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        disableAutoFocusItem
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Stack spacing={1} my={3}>
          {link.children?.map((childLink) => (
            <NextLink
              href={childLink.href}
              passHref
              key={childLink.label}
              legacyBehavior
            >
              <MenuItem
                component="a"
                sx={{ px: 6 }}
                target={childLink?.target || "_blank"}
                rel="noopener noreferrer"
              >
                {childLink.label}
              </MenuItem>
            </NextLink>
          ))}
        </Stack>
        {link.withSocials && (
          <>
            <Divider />

            <Box paddingX={4.5} paddingY={2}>
              <SocialNetworks
                gridTemplateColumns="repeat(4, max-content)"
                rowGap={3}
                columnGap={4}
                mt={4}
                color={"background.default"}
                height="100%"
              />
            </Box>
          </>
        )}
      </StyledMenu>
    </div>
  );
};

export const AppMenu: FC<AppMenuProps> = ({
  links,
  dashboardStyle,
  colorState = "light",
  ...props
}) => {
  const asPath = usePathname();

  return (
    <Stack
      direction="row"
      gap={5}
      {...props}
      alignItems="center"
      sx={{
        ...props.sx,
        height: "100%",
        paddingRight: 2,
      }}
    >
      {links.map((link) =>
        link.comingSoon ? (
          <Tooltip
            key={link.label}
            placement={"bottom"}
            title="Coming soon"
            arrow
          >
            <Typography component="span" color="text.secondary">
              {link.label}
            </Typography>
          </Tooltip>
        ) : !link.children ? (
          <NextLink href={link.href} key={link.href} passHref legacyBehavior>
            <AppMenuLink
              underline="none"
              title={link.label}
              target={link.target}
              $isActive={
                typeof link.isExact === "boolean"
                  ? link.isExact ?? true
                    ? asPath === link.href
                    : asPath.startsWith(link.href)
                  : link.isExact?.(asPath) || false
              }
              $isDashboardStyle={dashboardStyle}
            >
              <Typography
                variant={dashboardStyle ? "subtitle1" : "body1"}
                component="span"
              >
                {link.label}
              </Typography>
            </AppMenuLink>
          </NextLink>
        ) : (
          <AppMenuDropdownLink
            link={link}
            key={link.label}
            colorState={colorState}
          />
        )
      )}
    </Stack>
  );
};
